import React from "react";
import styled from "styled-components";

import Image from "../../components/image";
import Button from "../../components/button";

import BuffSrc from "./assets/buff.jpg";

const Paragraph = styled.p`
  max-width: 60ch;
`;

const StyledImage = styled(Image)`
  display: inline-block;
  border-radius: 5px;
  max-height: 400px;
  @media (max-width: 768px) {
    max-height: none;
    max-width: 100%;
  }
`;

const ContactLink = styled.a`
  display: block;
  margin-top: 0.5rem;
  text-decoration: none;
  :first-of-type {
    margin-top: 0;
  }
`;

const Buff = () => (
  <div>
    <StyledImage src={BuffSrc} alt="" />
    <br />
    <br />
    <Paragraph>
      Buff is a four-year-old pomeranian from the Jersey Shore. Very nice until
      about 9 pm.
    </Paragraph>
    <br />
    <ContactLink
      href="https://www.instagram.com/buffhaha/"
      target="_blank"
      rel="noreferrer"
    >
      <Button>@buffhaha</Button>
    </ContactLink>
  </div>
);

export default Buff;
